import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { Text, Container, Loader, Flex } from '@mantine/core';
import { AuthService } from 'Api/authService';
import { login } from 'Slices/authSlice';
// import ZenafideLogo from 'Src/assets/zenafide-logo.png';
import NaehasWhiteLogo from 'Src/assets/naehas-white-logo.png';
import { showErrorNotification } from 'Utils/notifications';

import { Dots } from './Dots';
import classes from './IRLanding.module.scss';

const IRLanding = () => {
  const { token } = useParams<{
    token: string;
  }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const processToken = useCallback(async () => {
    try {
      if (!token) {
        throw new Error('Invalid token');
      }
      const { data } = await AuthService.naehasAutoLogin(token);
      if (!data) {
        throw new Error('Something went wrong while processing token');
      }
      dispatch(login(data));
      // redirect user to assessment page
      const { batch_id, document_id } = data;
      const url = `/assessment/${batch_id}/${document_id}`;
      navigate(url);
    } catch (error: unknown) {
      if (error instanceof Error) {
        showErrorNotification(error.message);
      } else {
        showErrorNotification('Something went wrong while processing token');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    processToken();
  }, [processToken]);

  return (
    <Container className={classes.wrapper} size={1400}>
      <Dots className={classes.dots} style={{ left: 0, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />

      <div className={classes.inner}>
        <Flex justify="center">
          <Loader type="ring" size={100} />
        </Flex>
        <Container p={0} size={600}>
          <Text size="lg" c="dimmed" className={classes.description}>
            Preparing Assessment for Review. Please wait....
          </Text>
        </Container>
      </div>
      <img
        height={40}
        src={NaehasWhiteLogo}
        alt="Logo"
        className={classes.logo}
      />
    </Container>
  );
};

export default IRLanding;
