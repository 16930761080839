import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import DocumentService from 'Api/documentService';
import SnapshotService from 'Api/snapshotService';
import { LoadingStatus } from 'Constants/index';
import { DocumentDataType } from 'Types/docTypes';
import { genericReduxState } from 'Types/reduxGenericTypes';

import { handleAsyncActions } from './genericActionsUtility';

// Always use prefix for existing state
// as shown below entityLoading, entityError
interface activeDocumentState extends genericReduxState {
  data: DocumentDataType | null;
  snapshotsLoading?: LoadingStatus;
  snaphotsError?: string | null | undefined;
}

const initialState: activeDocumentState = {
  data: null,
  loading: LoadingStatus.IDLE,
  snapshotsLoading: LoadingStatus.IDLE,
  error: null,
  snaphotsError: null,
};

export const fetchActiveDocument = createAsyncThunk(
  'activeDocument/fetchData',
  async (docId: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await DocumentService.getDocumentsById(docId);
      const documentData = response.data;
      dispatch(fetchSnapshots(docId));
      return documentData;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchSnapshots = createAsyncThunk(
  'activeDocument/fetchSnapshots',
  async (docId: number, { rejectWithValue }) => {
    try {
      const response = await SnapshotService.getSnapshotsByDocId(docId);
      return response?.data?.data || [];
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

const activeDocumentSlice = createSlice({
  name: 'activeDocument',
  initialState,
  reducers: {
    resetActiveDocument: () => initialState,
    // Given a list of document snapshots, remove a snapshot by its ID
    // API call needs to happen before this action is dispatched
    removeSnapshotById: (
      state: activeDocumentState,
      action: PayloadAction<number>
    ) => {
      if (state.data) {
        const { snapshots = [] } = state.data;
        const filteredSnapshots = snapshots.filter(
          (snapshot) => snapshot.id !== action.payload
        );
        state.data.snapshots = filteredSnapshots;
      }
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, 'activeDocument/fetchData', 'loading', 'error');
    handleAsyncActions(
      builder,
      'activeDocument/fetchSnapshots',
      'snapshotsLoading',
      'snapshotsError',
      'snapshots'
    );
  },
});

export const { removeSnapshotById, resetActiveDocument } =
  activeDocumentSlice.actions;
export default activeDocumentSlice.reducer;
