import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Flex, ActionIcon, Divider, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';
import AssessmentService from 'Api/assessmentService';
import LoadingOverlay from 'Components/loading-overlay/LoadingOverlay';
import { DocumentSnapshotType } from 'Types/docTypes';
import { showErrorNotification } from 'Utils/notifications';
import { transformDateString } from 'Utils/transform';

import DisplayRuleEvals from '../DisplayRuleEvals';

type AssessmentPreviewProps = {
  currentSnapshot: DocumentSnapshotType;
  onClosePreview: () => void;
  footerActions: React.ReactNode;
};

const AssessmentPreview: React.FC<AssessmentPreviewProps> = ({
  currentSnapshot,
  onClosePreview,
  footerActions,
}) => {
  const { batchId, docId } = useParams<{
    batchId: string;
    docId: string;
  }>();
  const [ruleEvals, setRuleEvals] = useState([]);
  const [loading, handleLoading] = useDisclosure(false);

  useEffect(() => {
    if (currentSnapshot.id) fetchAssessmentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSnapshot.id]);

  const fetchAssessmentDetails = async () => {
    handleLoading.open();
    try {
      if (docId === undefined || batchId === undefined) {
        return;
      }
      if (currentSnapshot.id) {
        const { data: assessmentResponse } =
          await AssessmentService.getAssessmentByInfo(
            parseInt(batchId),
            parseInt(docId),
            currentSnapshot.id
          );
        if (assessmentResponse && assessmentResponse?.results) {
          const currentAssessmentData = assessmentResponse.results[0];
          const { data: detailedAssessmentData } =
            await AssessmentService.getAssessmentsById(
              currentAssessmentData.id
            );
          const ruleEvalsData =
            detailedAssessmentData?.report?.rule_evals || [];
          setRuleEvals(ruleEvalsData);
        }
      } else {
        throw new Error('No snapshot found');
      }
    } catch (error) {
      showErrorNotification(
        'Something went wrong while fetching assessment detail'
      );
    } finally {
      handleLoading.close();
    }
  };

  return (
    <Flex direction="column">
      <LoadingOverlay visible={loading} />
      <Flex pb="sm" gap="xs" justify={'space-between'} align={'center'}>
        {currentSnapshot?.created_at && (
          <Text size="sm">
            {transformDateString(currentSnapshot.created_at, true)}
          </Text>
        )}
        <ActionIcon variant="subtle" color="black" onClick={onClosePreview}>
          <IconX stroke={1.5} />
        </ActionIcon>
      </Flex>
      <Divider />
      <Flex
        mt="md"
        h="75vh"
        mb="lg"
        gap="sm"
        direction="column"
        style={{ overflowY: 'auto' }}
      >
        <DisplayRuleEvals
          loading={loading}
          ruleEvals={ruleEvals}
          viewMode={true}
          snapshotId={currentSnapshot?.id}
        />
      </Flex>
      {footerActions}
    </Flex>
  );
};

export default AssessmentPreview;
