import { AbilityBuilder, PureAbility } from '@casl/ability';
import { ACTIONS, SUBJECTS } from 'Constants/permissions';

const collectSubjectValues = (obj: Record<string, any>): string[] => {
  const values: string[] = [];
  function traverse(node: Record<string, any>) {
    for (const key in node) {
      if (typeof node[key] === 'string') {
        values.push(node[key]);
      } else if (typeof node[key] === 'object') {
        traverse(node[key]);
      }
    }
  }
  traverse(obj);
  return values;
};
const subjectValues = collectSubjectValues(SUBJECTS);
export type Actions = (typeof ACTIONS)[keyof typeof ACTIONS];
export type Subjects = (typeof subjectValues)[number];

export type AppAbility = PureAbility<[Actions, Subjects]>;

export function defineAbilitiesFor(
  permissions: { codename: string }[]
): AppAbility {
  const { can, build } = new AbilityBuilder<PureAbility<[Actions, Subjects]>>(
    PureAbility
  );

  permissions.forEach((permission) => {
    const [module, actionSubject] = permission.codename.split('.');
    if (actionSubject) {
      const [action, subject] = actionSubject.split('_');
      const fullSubject = `${module}.${subject}`;
      can(action as Actions, fullSubject);
    } else {
      can(actionSubject as Actions, module);
    }
  });

  return build();
}
