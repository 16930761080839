import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import AssessmentService from 'Api/assessmentService';
import { LoadingStatus } from 'Constants/index';
import { AssessmentDetailType } from 'Types/assessmentTypes';
import { genericReduxState } from 'Types/reduxGenericTypes';
import { RuleEvalType, RuleIdWithResetStatus } from 'Types/ruleTypes';

import { RootState } from '../store';
import { handleAsyncActions } from './genericActionsUtility';

interface activeAssessmentState extends genericReduxState {
  data: AssessmentDetailType | null;
}

const initialState: activeAssessmentState = {
  data: null,
  loading: LoadingStatus.IDLE,
  error: null,
};

// fetch favourite/latest snapshot by document data.
export const fetchActiveAssessment = createAsyncThunk(
  'activeAssessment/fetchData',
  async (
    { docId, batchId }: { docId: string; batchId: string },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const documentData = state.activeDocument.data;
    const latestSnapshotId =
      documentData?.favorite_snapshot.id || documentData?.latest_snapshot.id;
    if (latestSnapshotId !== undefined) {
      const { data: assessmentResponse } =
        await AssessmentService.getAssessmentByInfo(
          parseInt(batchId),
          parseInt(docId),
          latestSnapshotId
        );
      if (assessmentResponse && assessmentResponse.results) {
        const assessmentDetails = assessmentResponse.results[0];
        const { data: assessmentDetailedData } =
          await AssessmentService.getAssessmentsById(assessmentDetails.id);
        if (assessmentDetailedData) return assessmentDetailedData;
        rejectWithValue('Error while fetching assessment details');
      } else {
        return rejectWithValue('No results found');
      }
    } else {
      return rejectWithValue('No snapshot found');
    }
  }
);

export const reRunActiveAssessment = createAsyncThunk(
  'activeAssessment/reRun',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const assessmentDetail = state.activeAssessment.data;

    if (!assessmentDetail) {
      return rejectWithValue('No active assessment found');
    }

    const ruleEvals = assessmentDetail.report.rule_evals;
    const rulesToSend: RuleIdWithResetStatus = ruleEvals.reduce(
      (acc: RuleIdWithResetStatus, ruleEval: RuleEvalType) => {
        acc[ruleEval.rule.id] = { reset_status: true };
        return acc;
      },
      {} as RuleIdWithResetStatus
    );

    const { data } = await AssessmentService.reinitializePolicyEvals(
      assessmentDetail.id,
      rulesToSend
    );
    return data;
  }
);

const activeAssessmentSlice = createSlice({
  name: 'activeAssessment',
  initialState,
  reducers: {
    resetActiveAssessment: () => initialState,
    updateActiveAssessment: (
      state: activeAssessmentState,
      action: PayloadAction<AssessmentDetailType>
    ) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(
      builder,
      'activeAssessment/fetchData',
      'loading',
      'error'
    );
    handleAsyncActions(builder, 'activeAssessment/reRun', 'loading', 'error');
  },
});

export const { updateActiveAssessment, resetActiveAssessment } =
  activeAssessmentSlice.actions;
export default activeAssessmentSlice.reducer;
