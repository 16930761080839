export const ACTIONS = {
  ADD: 'add',
  CHANGE: 'change',
  DELETE: 'delete',
  VIEW: 'view',
  RUN: 'run',
} as const;

// pattern => module.subject
// eg of usage
// <Can I="ACTIONS.CHANGE" subject="NH_BO.DASHBOARD">
//   <SomeComponent />
// </Can>
export const SUBJECTS = {
  ADMIN: {
    LOGENTRY: 'admin.logentry',
  },
  AUTH: {
    GROUP: 'auth.group',
    PERMISSION: 'auth.permission',
  },
  COMPARISONS: {
    COMPARISON: 'comparisons.comparison',
  },
  CONTENTTYPES: {
    CONTENTTYPE: 'contenttypes.contenttype',
  },
  DISCUSSIONS: {
    DISCUSSION: 'discussions.discussion',
    POST: 'discussions.post',
  },
  DOCUMENTS: {
    BATCH: 'documents.batch',
    DOCUMENT: 'documents.document',
    DOCUMENTSNAPSHOT: 'documents.documentsnapshot',
    COPILOT: 'documents.copilot',
  },
  IR_RULE_ENGINE: {
    ZENRULES: 'ir_rule_engine.zenrules',
  },
  KNOX: {
    AUTHTOKEN: 'knox.authtoken',
  },
  MULTITENANT: {
    TENANT: 'multitenant.tenant',
    TENANTUSER: 'multitenant.tenantuser',
  },
  NH_BO: {
    DASHBOARD: 'nh_bo.dashboard',
    DASHBOARDWIDGET: 'nh_bo.dashboardwidget',
    DASHBOARDWIDGETTYPE: 'nh_bo.dashboardwidgettype',
    DEFAULTDASHBOARD: 'nh_bo.defaultdashboard',
    GO: 'nh_bo.go',
    GOATTACHMENT: 'nh_bo.goattachment',
    GONOTE: 'nh_bo.gonote',
    GOSEARCHREPORT: 'nh_bo.gosearchreport',
    GOSNAPSHOT: 'nh_bo.gosnapshot',
    GOSTATUSCHANGE: 'nh_bo.gostatuschange',
    GOTYPE: 'nh_bo.gotype',
  },
} as const;
