import {
  LoadingOverlay as MantineLoadingOverlay,
  useMantineTheme,
  LoadingOverlayProps as MantineLoadingOverlayProps,
} from '@mantine/core';

type TLoadingOverlay = MantineLoadingOverlayProps & {
  type?: 'bars' | 'dots' | 'oval' | 'ring' | 'tail-spin' | 'three-dots';
};

const LoadingOverlay: React.FC<TLoadingOverlay> = ({
  visible,
  type = 'bars',
  ...rest
}) => {
  const theme = useMantineTheme();
  return (
    <MantineLoadingOverlay
      visible={visible}
      loaderProps={{ color: theme.colors.primary[3], type }}
      {...rest}
    />
  );
};

export default LoadingOverlay;
