import { LoginCredentials } from 'Types/authTypes';

import { request } from './httpService';

const pathPrefix = '/api/v1/auth';

export const AuthService = {
  login: async (credentials: LoginCredentials) => {
    try {
      credentials.email = credentials.email
        ? credentials.email.toLowerCase()
        : '';
      const { email, password } = credentials;
      const encodedCredentials = btoa(`${email}:${password}`);
      return request({
        url: `${pathPrefix}/token-auth/login/`,
        method: 'post',
        headers: {
          Authorization: `Basic ${encodedCredentials}`,
        },
      });
    } catch (error) {
      console.error('Login error', error);
      throw error;
    }
  },
  logout: async () => {
    return request({
      url: `${pathPrefix}/token-auth/logout/`,
      method: 'post',
    });
  },
  getUserPermissions: async (userId: number) => {
    return request({
      url: `${pathPrefix}/user-permission/${userId}/`,
      method: 'get',
    });
  },
  naehasAutoLogin: async (token: string) => {
    const encodedToken = btoa(`${token}`);
    return request({
      url: `${pathPrefix}/partner-redirect-token/`,
      method: 'post',
      headers: {
        Authorization: `Basic ${encodedToken}`,
      },
    });
  },
};
