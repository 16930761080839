import NaehasWhiteLogo from 'Src/assets/naehas-white-logo.png';
import ZenafideWhiteLogo from 'Src/assets/zenafide-logo-white.svg';
import ZenafideLogo from 'Src/assets/zenafide-logo.png';

interface RenderLogoProps {
  user?: any;
  isWhite?: boolean;
  height?: number;
}

const renderLogo: React.FC<RenderLogoProps> = ({
  user,
  isWhite = false,
  height = 40,
}) => {
  const emailDomains = ['pathward'];
  const isNaehasUser = emailDomains.some((domain) =>
    user?.email?.toLowerCase()?.includes(domain)
  );

  if (user && isNaehasUser) {
    return (
      <img
        height={height}
        style={{ cursor: 'pointer', filter: isWhite ? undefined : 'invert(1)' }}
        src={NaehasWhiteLogo}
        alt="Naehas"
      />
    );
  }
  return (
    <img
      height={height}
      style={{ cursor: 'pointer' }}
      src={isWhite ? ZenafideWhiteLogo : ZenafideLogo}
      alt="Zenafide"
    />
  );
};

export { renderLogo };
